import React from 'react';
import { Box, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const ColumnSelector = ({ selectedColumns, handleColumnChange }) => {
    return (
        <Box p={2}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Select Columns</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={selectedColumns.name} onChange={handleColumnChange} name="name" />}
                        label="Name"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedColumns.contacts} onChange={handleColumnChange} name="contacts" />}
                        label="Contacts"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedColumns.assets} onChange={handleColumnChange} name="assets" />}
                        label="Assets"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedColumns.nextInvoice} onChange={handleColumnChange} name="nextInvoice" />}
                        label="Next Invoice"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedColumns.active} onChange={handleColumnChange} name="active" />}
                        label="Active"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedColumns.actions} onChange={handleColumnChange} name="actions" />}
                        label="Actions"
                    />
                </FormGroup>
            </FormControl>
        </Box>
    );
};

export default ColumnSelector;
