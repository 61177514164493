import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, TablePagination, Checkbox, IconButton, Switch, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AccountsTable = ({ accounts, loading, selectedAccounts, handleSelectAccount, handleToggleActive, navigate, setDeleteDialogOpen, setSelectedAccount, setSelectedAccounts, setAccounts, selectedColumns }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [sortDirection, setSortDirection] = React.useState('asc');
    const [sortBy, setSortBy] = React.useState('name');

    const handleSort = (column) => {
        const isAsc = sortBy === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortBy(column);

        const sortedAccounts = [...accounts].sort((a, b) => {
            if (column === 'name') {
                return isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            } else if (column === 'assets') {
                const aAssets = a.assets && a.assets.length > 0 ? a.assets.map(asset => asset.product.name).join(', ') : '';
                const bAssets = b.assets && b.assets.length > 0 ? b.assets.map(asset => asset.product.name).join(', ') : '';
                return isAsc ? aAssets.localeCompare(bAssets) : bAssets.localeCompare(aAssets);
            } else if (column === 'nextInvoice') {
                const aNextInvoice = a.assets && a.assets.length > 0 ? getNextInvoice(a.assets).dueDate : null;
                const bNextInvoice = b.assets && b.assets.length > 0 ? getNextInvoice(b.assets).dueDate : null;
                if (!aNextInvoice && !bNextInvoice) return 0;
                if (!aNextInvoice) return isAsc ? 1 : -1;
                if (!bNextInvoice) return isAsc ? -1 : 1;
                return isAsc ? aNextInvoice - bNextInvoice : bNextInvoice - aNextInvoice;
            }
            return 0;
        });

        setAccounts(sortedAccounts);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getNextInvoice = (assets) => {
        const assetWithDueDate = assets.find(asset => asset.dueDate);
        if (assetWithDueDate) {
            const dueDate = new Date(assetWithDueDate.dueDate);
            const roundedSalePrice = Math.round(assetWithDueDate.salePrice);
            const formattedSalePrice = new Intl.NumberFormat('en-US').format(roundedSalePrice);
            return {
                text: `$${formattedSalePrice} on ${dueDate.toLocaleDateString()}`,
                dueDate: dueDate,
            };
        }
        return { text: 'N/A', dueDate: null };
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={selectedAccounts.length > 0 && selectedAccounts.length < accounts.length}
                                checked={accounts.length > 0 && selectedAccounts.length === accounts.length}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setSelectedAccounts(accounts.map(account => account._id));
                                    } else {
                                        setSelectedAccounts([]);
                                    }
                                }}
                            />
                        </TableCell>
                        {selectedColumns.name && (
                            <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>
                                <TableSortLabel
                                    active={sortBy === 'name'}
                                    direction={sortDirection}
                                    onClick={() => handleSort('name')}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                        )}
                        {selectedColumns.contacts && (
                            <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Contacts</TableCell>
                        )}
                        {selectedColumns.assets && (
                            <TableCell sx={{ fontWeight: 'bold', padding: '4px', minWidth: '10%' }}>
                                <TableSortLabel
                                    active={sortBy === 'assets'}
                                    direction={sortDirection}
                                    onClick={() => handleSort('assets')}
                                >
                                    Assets
                                </TableSortLabel>
                            </TableCell>
                        )}
                        {selectedColumns.nextInvoice && (
                            <TableCell sx={{ fontWeight: 'bold', padding: '4px', width: '15%' }}>
                                <TableSortLabel
                                    active={sortBy === 'nextInvoice'}
                                    direction={sortDirection}
                                    onClick={() => handleSort('nextInvoice')}
                                >
                                    Next Invoice
                                </TableSortLabel>
                            </TableCell>
                        )}
                        {selectedColumns.active && (
                            <TableCell sx={{ fontWeight: 'bold', padding: '4px', width: '50px' }}>
                                Active
                            </TableCell>
                        )}
                        {selectedColumns.actions && (
                            <TableCell sx={{ fontWeight: 'bold', padding: '4px', width: '100px' }}>Actions</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRow>
                            <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                                Loading...
                            </TableCell>
                        </TableRow>
                    ) : (
                        accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((account) => (
                            <TableRow key={account._id} sx={{ padding: '2px 0' }}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedAccounts.includes(account._id)}
                                        onChange={() => handleSelectAccount(account)}
                                    />
                                </TableCell>
                                {selectedColumns.name && (
                                    <TableCell
                                        sx={{ padding: '4px', color: account.requiresAttention ? 'red' : (account.active ? 'inherit' : 'grey'), cursor: 'pointer' }}
                                        onClick={() => navigate(`/edit-account/${account._id}`)}
                                    >
                                        {account.name}
                                    </TableCell>
                                )}
                                {selectedColumns.contacts && (
                                    <TableCell sx={{ padding: '4px', color: account.requiresAttention ? 'red' : (account.active ? 'inherit' : 'grey') }}>
                                        {account.contacts && account.contacts.length > 0
                                            ? account.contacts.length <= 3
                                                ? account.contacts.map(contact => contact.email).join(', ')
                                                : `${account.contacts.slice(0, 3).map(contact => contact.email).join(', ')}...(${account.contacts.length}) contacts`
                                            : 'N/A'}
                                    </TableCell>
                                )}
                                {selectedColumns.assets && (
                                    <TableCell sx={{ padding: '4px', color: account.requiresAttention ? 'red' : (account.active ? 'inherit' : 'grey') }}>
                                        {account.assets && account.assets.length > 0
                                            ? account.assets.map(asset => (
                                                <div
                                                    key={asset._id}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => navigate(`/asset/${asset._id}`)}
                                                >
                                                    {asset.product.name}
                                                </div>
                                            ))
                                            : 'N/A'}
                                    </TableCell>
                                )}
                                {selectedColumns.nextInvoice && (
                                    <TableCell sx={{ padding: '4px', color: account.requiresAttention ? 'red' : (account.active ? 'inherit' : 'grey') }}>
                                        {getNextInvoice(account.assets).text}
                                    </TableCell>
                                )}
                                {selectedColumns.active && (
                                    <TableCell sx={{ padding: '4px' }}>
                                        <Switch
                                            checked={account.active}
                                            onChange={() => handleToggleActive(account)}
                                            color="primary"
                                        />
                                    </TableCell>
                                )}
                                {selectedColumns.actions && (
                                    <TableCell sx={{ padding: '4px' }}>
                                        <IconButton
                                            aria-label="edit"
                                            onClick={() => navigate(`/edit-account/${account._id}`)}
                                            sx={{ padding: '4px' }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                setDeleteDialogOpen(true);
                                                setSelectedAccount(account);
                                            }}
                                            sx={{ padding: '4px' }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={accounts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};

export default AccountsTable;
