import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography,
    TextField, Button, Grid, Box
} from '@mui/material';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@mui/icons-material';
import axios from 'axios';

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

const PendingTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 14)).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

    const fetchTransactions = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/braintree/transactions/failed`, {
                params: {
                    startDate,
                    endDate,
                },
            });
            setTransactions(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching transactions', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, [startDate, endDate]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" marginTop={2}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box padding={2}>
            <Typography variant="h4" margin={2}>Failed Transactions</Typography>
            <Grid container spacing={2} marginBottom={2} alignItems="center">
                <Grid item>
                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ style: { height: '40px' } }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ style: { height: '40px' } }}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={fetchTransactions} style={{ height: '40px' }}>
                        Search
                    </Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Email</strong></TableCell> {/* Updated column header */}
                            <TableCell><strong>Transaction ID</strong></TableCell> {/* Updated to be after Email */}
                            <TableCell><strong>Amount</strong></TableCell>
                            <TableCell><strong>Status</strong></TableCell>
                            <TableCell><strong>Date</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.map((transaction) => (
                            <TableRow key={transaction.transactionId}>
                                <TableCell>
                                    <a href={`https://www.braintreegateway.com/merchants/m24sdg95vptp98vr/customers/${transaction.customerId}`} target="_blank" rel="noopener noreferrer">
                                        {transaction.email}
                                    </a>
                                    </TableCell>
                                <TableCell>
                                    <a href={`https://www.braintreegateway.com/merchants/m24sdg95vptp98vr/transactions/${transaction.transactionId}`} target="_blank" rel="noopener noreferrer">
                                        {transaction.transactionId}
                                    </a>
                                </TableCell>
                                <TableCell>${transaction.amount}</TableCell>
                                <TableCell><Box display="flex" alignItems="center">
                                    <CancelIcon style={{ color: 'red' }} />
                                    <span style={{ marginLeft: '8px' }}>{transaction.status}</span>
                                </Box></TableCell>
                                <TableCell>{formatDate(transaction.date)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default PendingTransactions;
