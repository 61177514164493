import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    TextField,
    Button,
    Typography,
    Container,
    Grid,
    Paper,
    FormControlLabel,
    Checkbox // Import Checkbox component
} from '@mui/material';

const ContactForm = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [contact, setContact] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
        account: '',
        paymentContact: false,
        title: '', // Added field
        notes: '' // Added field
    });
    const [account, setAccount] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const accountId = query.get('accountId');
        if (id !== 'new') {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/contacts/${id}`).then((response) => {
                setContact(response.data);
                return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${response.data.account}`);
            }).then((response) => {
                setAccount(response.data);
            }).catch(error => {
                console.error("Error fetching contact or account data:", error);
            });
        } else if (accountId) {
            setContact((prevContact) => ({
                ...prevContact,
                account: accountId
            }));
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${accountId}`).then((response) => {
                setAccount(response.data);
            }).catch(error => {
                console.error("Error fetching account data:", error);
            });
        }
    }, [id, location.search]);

    const validateFields = () => {
        let tempErrors = {};
        if (!contact.email) tempErrors.email = 'Email is required';
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setContact((prevContact) => ({
            ...prevContact,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateFields()) {
            const method = id === 'new' ? 'post' : 'put';
            const url = id === 'new' ? `${process.env.REACT_APP_BACKEND_URL}/api/contacts` : `${process.env.REACT_APP_BACKEND_URL}/api/contacts/${id}`;
            axios[method](url, contact).then(() => {
                navigate(-1); // Go back to the previous page (Account page)
            }).catch(error => {
                console.error(`Error ${id === 'new' ? 'creating' : 'updating'} contact:`, error);
            });
        }
    };

    const handleCancel = () => {
        navigate(-1); // Go back to the previous page (Account page)
    };

    return (
        <Container component="main" maxWidth="md">
            <Paper style={{ padding: 16 }}>
                <Typography variant="h6" gutterBottom>
                    {id === 'new' ? 'New Contact' : 'Edit Contact'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Account"
                                value={account.name || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="firstName"
                                label="First Name"
                                value={contact.firstName}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="lastName"
                                label="Last Name"
                                value={contact.lastName}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                name="email"
                                label="Email"
                                type="email"
                                value={contact.email}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.email}
                                helperText={errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="phone"
                                label="Phone"
                                value={contact.phone}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="address"
                                label="Address"
                                value={contact.address}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="city"
                                label="City"
                                value={contact.city}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="state"
                                label="State"
                                value={contact.state}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="zip"
                                label="Zip"
                                value={contact.zip}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="country"
                                label="Country"
                                value={contact.country}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="title"
                                label="Title"
                                value={contact.title}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="notes"
                                label="Notes"
                                value={contact.notes}
                                onChange={handleChange}
                                fullWidth
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="paymentContact"
                                        checked={contact.paymentContact}
                                        onChange={handleChange}
                                    />
                                }
                                label="Payment Contact"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
};

export default ContactForm;
