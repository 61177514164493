import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`);
                setProducts(sortProducts(response.data));
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const sortProducts = (products) => {
        return products.sort((a, b) => {
            if (a.active === b.active) {
                return a.name.localeCompare(b.name);
            }
            return a.active ? -1 : 1;
        });
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/products/${id}`);
            setProducts(sortProducts(products.filter(product => product._id !== id)));
            handleClose();
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    const handleToggle = async (id) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/products/${id}/toggle`);
            setProducts(sortProducts(products.map(product => product._id === id ? response.data : product)));
        } catch (error) {
            console.error('Error toggling product status:', error);
        }
    };

    const handleClickOpen = (product) => {
        setSelectedProduct(product);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedProduct(null);
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Products
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/new-product')}
                sx={{ mb: 2 }}
            >
                New Product
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>List Price</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((product) => (
                            <TableRow key={product._id}>
                                <TableCell sx={{ color: product.active ? 'inherit' : 'gray' }}>{product.name}</TableCell>
                                <TableCell sx={{ color: product.active ? 'inherit' : 'gray' }}>{product.type}</TableCell>
                                <TableCell sx={{ color: product.active ? 'inherit' : 'gray' }}>{formatCurrency(product.listPrice)}</TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() => navigate(`/edit-product/${product._id}`)}
                                        sx={{ mr: 1, color: product.active ? 'inherit' : 'gray' }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => handleClickOpen(product)}
                                        sx={{ color: product.active ? 'inherit' : 'gray' }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label={product.active ? 'disable' : 'enable'}
                                        onClick={() => handleToggle(product._id)}
                                        sx={{ color: product.active ? 'primary.main' : 'gray' }}
                                    >
                                        {product.active ? <ToggleOnIcon /> : <ToggleOffIcon />}
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Product"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the product "{selectedProduct?.name}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDelete(selectedProduct._id)} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Products;
