import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, MenuItem } from '@mui/material';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const OpportunityForm = () => {
    const [account, setAccount] = useState('');
    const [product, setProduct] = useState('');
    const [price, setPrice] = useState('');
    const [stage, setStage] = useState('inception');
    const [primaryContact, setPrimaryContact] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [products, setProducts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const [accountResponse, productResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts`),
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`)
            ]);
            setAccounts(accountResponse.data);
            setProducts(productResponse.data);
        };

        fetchData();

        if (id) {
            const fetchOpportunity = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/opportunities/${id}`);
                    const opportunity = response.data;
                    setAccount(opportunity.account._id);
                    setProduct(opportunity.product._id);
                    setPrice(opportunity.price);
                    setStage(opportunity.stage); // No need to change case here
                    setPrimaryContact(opportunity.primaryContact._id);
                } catch (error) {
                    console.error('Error fetching opportunity:', error);
                }
            };
            fetchOpportunity();
        }
    }, [id]);

    useEffect(() => {
        if (account) {
            const fetchContacts = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/contacts/account/${account}`);
                    console.error(`Fetched ${response.data.length} contacts for account ${JSON.stringify(account)}`);
                    setContacts(response.data);
                } catch (error) {
                    console.error('Error fetching contacts:', error);
                }
            };
            fetchContacts();
        } else {
            setContacts([]);
        }
    }, [account]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const opportunityData = {
            account,
            product,
            price: parseFloat(price),
            stage,
            primaryContact
        };
        try {
            if (id) {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/opportunities/${id}`, opportunityData);
            } else {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/opportunities`, opportunityData);
            }
            navigate('/opportunities'); // Navigate back to the Opportunities page
        } catch (error) {
            console.error('Error saving opportunity:', error);
        }
    };

    const handleCancel = () => {
        navigate('/opportunities');
    };

    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" gutterBottom>
                    {id ? 'Edit Opportunity' : 'Create New Opportunity'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Account"
                        select
                        value={account}
                        onChange={(e) => setAccount(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    >
                        {accounts.map(account => (
                            <MenuItem key={account._id} value={account._id}>
                                {account.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Product"
                        select
                        value={product}
                        onChange={(e) => {
                            setProduct(e.target.value);
                            const selectedProduct = products.find(p => p._id === e.target.value);
                            if (selectedProduct) {
                                setPrice(selectedProduct.listPrice.toFixed(2));
                            }
                        }}
                        fullWidth
                        margin="normal"
                        required
                    >
                        {products.map(product => (
                            <MenuItem key={product._id} value={product._id}>
                                {product.name} - ${product.listPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Price"
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Stage"
                        select
                        value={stage}
                        onChange={(e) => setStage(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    >
                        {['inception', 'price submitted', 'invoice', 'closed won', 'closed lost'].map(stage => (
                            <MenuItem key={stage} value={stage}>
                                {stage.charAt(0).toUpperCase() + stage.slice(1)}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        label="Primary Contact"
                        select
                        value={primaryContact}
                        onChange={(e) => setPrimaryContact(e.target.value)}
                        fullWidth
                        margin="normal"
                    >
                        {contacts.map(contact => (
                            <MenuItem key={contact._id} value={contact._id}>
                                {contact.firstName} {contact.lastName} &lt;{contact.email}&gt;
                            </MenuItem>
                        ))}
                    </TextField>
                    <Box mt={2}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mr: 2 }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default OpportunityForm;
