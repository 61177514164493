import React from 'react';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { Box, Typography, Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";

const Login = ({ setUser }) => {
    const navigate = useNavigate();

    const handleLoginSuccess = async (response) => {
        try {
            // console.log('credential: ' + response.credential);
            // const backendResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/google`, {
            //     token: response.credential,
            // });

            //const { id_token } = backendResponse.data;
            const userObject = jwtDecode(response.credential);
            const email = userObject.email;
            const firstName = userObject.given_name;
            const lastName = userObject.family_name;

            // Call create-if-new endpoint
            const createIfNewResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/create-if-new`, {
                username: email,
                firstName,
                lastName
            });

            let userId;
            let group;
            if (createIfNewResponse.data.exists) {
                // If user exists, check if they are active and get the userId
                const encodedEmail = encodeURIComponent(email);
                const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${encodedEmail}`);
                if (!userResponse.data.active) {
                    console.error('User is inactive.');
                    googleLogout();
                    alert('Your account is inactive. Please contact support.');
                    return;
                }
                userId = userResponse.data._id; // Get the userId from the existing user
                group = userResponse.data.group;
            } else {
                userId = createIfNewResponse.data.userId; // Get the userId from the newly created user
                group = 'User';
            }

            // Create the user object to be stored in localStorage
            const storedUserObject = {
                userId,  // Include the database user ID
                email,
                firstName,
                lastName,
                group
            };

            setUser(storedUserObject);
            localStorage.setItem('user', JSON.stringify(storedUserObject));
            navigate('/accounts');  // Navigate to accounts page after successful login
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    const handleLoginFailure = (error) => {
        console.error('Login Failed:', error);
    };

    return (
        <Container>
            <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                <Box textAlign="center">
                    <Typography variant="h4" gutterBottom>
                        Login
                    </Typography>
                    <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        onError={handleLoginFailure}
                        useOneTap
                    />
                </Box>
            </Grid>
        </Container>
    );
};

export default Login;
