import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Chip,
    TableSortLabel,
    Menu,
    MenuItem,
    Tooltip,
    TablePagination
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RepeatIcon from '@mui/icons-material/Repeat';
import FilterListIcon from '@mui/icons-material/FilterList';

const Invoices = () => {
    const [invoices, setInvoices] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [filterState, setFilterState] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');
    const [minTotal, setMinTotal] = useState('');
    const [maxTotal, setMaxTotal] = useState('');
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('invoiceDate');

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMenuInvoice, setSelectedMenuInvoice] = useState(null);
    const [statusAnchorEl, setStatusAnchorEl] = useState(null);
    const [selectedStatusInvoice, setSelectedStatusInvoice] = useState(null);
    const navigate = useNavigate();

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalItemsCount, setTotalItemsCount] = useState(0);

    useEffect(() => {
        fetchInvoices(page, rowsPerPage, orderBy, order, searchTerm);
    }, [page, rowsPerPage, orderBy, order, searchTerm]);

    const fetchInvoices = async (pageNumber, limit, sortField, sortOrder, search) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/invoices`, {
                params: {
                    page: pageNumber + 1,
                    limit,
                    sortField,
                    sortOrder,
                    search
                },
            });
            setInvoices(response.data.invoices);
            setTotalItemsCount(response.data.totalCount);
        } catch (error) {
            console.error('Error fetching invoices:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/invoices/${id}`);
            setInvoices(invoices.filter(invoice => invoice._id !== id));
            handleClose();
        } catch (error) {
            console.error('Error deleting invoice:', error);
        }
    };

    const handleClickOpen = (invoice) => {
        setSelectedInvoice(invoice);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedInvoice(null);
    };

    const handleViewPDF = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/invoices/pdf/${id}`, {
                responseType: 'blob',
            });
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const handleDuplicate = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/invoices/duplicate/${id}`);
            const duplicatedInvoice = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/invoices/${response.data._id}`);
            setInvoices([...invoices, duplicatedInvoice.data]);
        } catch (error) {
            console.error('Error duplicating invoice:', error);
        }
    };

    const handleChangeState = async (id, newState) => {
        try {
            const recurrence = newState === 'Recurrent' ? 'Monthly' : 'None';
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/invoices/${id}/state`, { state: newState, recurrence });
            const updatedInvoice = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/invoices/${id}`);
            setInvoices(invoices.map(invoice => (invoice._id === id ? updatedInvoice.data : invoice)));
        } catch (error) {
            console.error('Error updating invoice state:', error);
        }
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortComparator = (a, b, orderBy) => {
        if (orderBy === 'account') {
            return a.account?.name.localeCompare(b.account?.name);
        }
        if (orderBy === 'invoiceDate' || orderBy === 'invoiceDueDate') {
            return new Date(a[orderBy]) - new Date(b[orderBy]);
        }
        if (orderBy === 'state') {
            return a.state.localeCompare(b.state);
        }
        if (orderBy === 'total') {
            return a.total - b.total;
        }
        return 0;
    };

    const filteredInvoices = invoices.filter(invoice => {
        const total = invoice.total || 0;
        const isWithinTotalRange = (minTotal === '' || total >= parseFloat(minTotal)) &&
            (maxTotal === '' || total <= parseFloat(maxTotal));

        return (filterState === 'All' || invoice.state === filterState) &&
            (invoice.account?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.poNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.notes?.toLowerCase().includes(searchTerm.toLowerCase())) &&
            isWithinTotalRange;
    });

    const sortedInvoices = [...filteredInvoices].sort((a, b) => {
        const comparator = sortComparator(a, b, orderBy);
        return order === 'desc' ? -comparator : comparator;
    });

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const getChipColor = (state) => {
        switch (state) {
            case 'Draft':
                return 'orange';
            case 'Paid':
                return 'green';
            case 'Sent':
                return 'lightblue';
            case 'Void':
                return 'grey';
            case 'Recurrent':
                return 'navy';
            default:
                return 'default';
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Draft':
                return 'orange';
            case 'Paid':
                return 'green';
            case 'Sent':
                return 'lightblue';
            case 'Void':
                return 'grey';
            case 'Recurrent':
                return 'navy';
            default:
                return 'default';
        }
    };

    const handleStatusClick = (event, invoice) => {
        setStatusAnchorEl(event.currentTarget);
        setSelectedStatusInvoice(invoice);
    };

    const handleStatusClose = () => {
        setStatusAnchorEl(null);
        setSelectedStatusInvoice(null);
    };

    const handleStatusChange = (status) => {
        handleChangeState(selectedStatusInvoice._id, status);
        handleStatusClose();
    };

    const handleFilterIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const handleFilterChange = (state) => {
        setFilterState(state);
        handleFilterClose();
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    function formatISODateToMMDDYYYY(isoDate) {
        const date = new Date(isoDate);
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based
        const day = String(date.getUTCDate()).padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${month}/${day}/${year}`;
    }

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h4" gutterBottom>
                        Invoices
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/edit-invoice/new')}
                        sx={{ ml: 2 }}
                    >
                        New Invoice
                    </Button>
                </Box>
                <Box display="flex" alignItems="center" ml="auto">
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        sx={{ width: '250px' }}
                    />
                    <IconButton
                        aria-label="filter"
                        onClick={handleFilterIconClick}
                        sx={{ ml: 1 }}
                    >
                        <FilterListIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleFilterClose}
                    >
                        {['All', 'Draft', 'Sent', 'Paid', 'Void', 'Recurrent'].map((state) => (
                            <MenuItem key={state} onClick={() => handleFilterChange(state)}>
                                {state}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Number</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'state'}
                                    direction={orderBy === 'state' ? order : 'asc'}
                                    onClick={() => handleRequestSort('state')}
                                >
                                    Status
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'account'}
                                    direction={orderBy === 'account' ? order : 'asc'}
                                    onClick={() => handleRequestSort('account')}
                                >
                                    Account
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold'}}>
                                <TableSortLabel
                                    active={orderBy === 'total'}
                                    direction={orderBy === 'total' ? order : 'asc'}
                                    onClick={() => handleRequestSort('total')}
                                >
                                    Total
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'invoiceDate'}
                                    direction={orderBy === 'invoiceDate' ? order : 'asc'}
                                    onClick={() => handleRequestSort('invoiceDate')}
                                >
                                    Invoice Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'invoiceDueDate'}
                                    direction={orderBy === 'invoiceDueDate' ? order : 'asc'}
                                    onClick={() => handleRequestSort('invoiceDueDate')}
                                >
                                    Due Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                Type
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', width: '120px' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedInvoices.map((invoice) => (
                            <TableRow key={invoice._id}>
                                <TableCell>{invoice.number}</TableCell>
                                <TableCell>
                                    <Chip
                                        label={
                                            <Box display="flex" alignItems="center">
                                                {invoice.state}
                                                {statusAnchorEl && selectedStatusInvoice?._id === invoice._id ?
                                                    <ArrowRightIcon /> : <ArrowDropDownIcon />}
                                            </Box>
                                        }
                                        style={{ backgroundColor: getChipColor(invoice.state), color: 'white', cursor: 'pointer' }}
                                        onClick={(event) => handleStatusClick(event, invoice)}
                                    />
                                    <Menu
                                        anchorEl={statusAnchorEl}
                                        open={Boolean(statusAnchorEl)}
                                        onClose={handleStatusClose}
                                    >
                                        {['Draft', 'Sent', 'Paid', 'Void', 'Recurrent'].map(status => (
                                            <MenuItem key={status} onClick={() => handleStatusChange(status)}>
                                                <Box display="flex" alignItems="center">
                                                    <Box
                                                        sx={{
                                                            width: 12,
                                                            height: 12,
                                                            borderRadius: '50%',
                                                            backgroundColor: getStatusColor(status),
                                                            marginRight: 1
                                                        }}
                                                    />
                                                    {status}
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </TableCell>
                                <TableCell>
                                    <Link to={`/edit-account/${invoice.account?._id}`}>
                                        {invoice.account?.name}
                                    </Link>
                                </TableCell>
                                <TableCell>{formatCurrency(invoice.total)}</TableCell>
                                <TableCell>{formatISODateToMMDDYYYY(new Date(invoice.invoiceDate))}</TableCell>
                                <TableCell>{formatISODateToMMDDYYYY(new Date(invoice.invoiceDueDate))}</TableCell>
                                <TableCell>
                                    {invoice.type !== 'Invoice' && invoice.type}
                                    {invoice.recurrence !== 'None' && (
                                        <Tooltip title="Recurring Invoice">
                                            <RepeatIcon style={{ marginLeft: 8 }} />
                                        </Tooltip>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="View PDF">
                                        <IconButton
                                            aria-label="view"
                                            onClick={() => handleViewPDF(invoice._id)}
                                            style={{ padding: '0px 4px 0px 0px', margin: '0px' }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit Invoice">
                                        <IconButton
                                            aria-label="edit"
                                            onClick={() => navigate(`/edit-invoice/${invoice._id}`)}
                                            style={{ padding: '0px 4px 0px 0px', margin: '0px' }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete Invoice">
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => handleClickOpen(invoice)}
                                            style={{ padding: '0px 4px 0px 0px', margin: '0px' }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Duplicate Invoice">
                                        <IconButton
                                            aria-label="duplicate"
                                            onClick={() => handleDuplicate(invoice._id)}
                                            style={{ padding: '0px 2px 0px 0px', margin: '0px' }}
                                        >
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalItemsCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Invoice"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the invoice #{selectedInvoice?.number}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDelete(selectedInvoice._id)} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );

};

export default Invoices;
