import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Button, TextField, IconButton, Popover } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { useNavigate } from 'react-router-dom';
import AccountsTable from './Account/AccountTable';
import AccountFilter from './Account/AccountFilter';
import ColumnSelector from './Account/ColumnSelector';
import MergeDialog from './Account/MergeDialog';
import DeleteDialog from './Account/DeleteDialog';
import ClearIcon from '@mui/icons-material/Clear';

const Accounts = () => {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [columnsAnchorEl, setColumnsAnchorEl] = useState(null);
    const [filterOptions, setFilterOptions] = useState({
        showProblemAccountsOnly: false,
        hideInactive: true, // Set to true to enable by default
        hideWithoutAssets: false,
        showOnlyWithoutAssets: false,
        showInvoiced: false,
    });
    const [selectedColumns, setSelectedColumns] = useState({
        name: true,
        contacts: false,
        assets: true,
        nextInvoice: true,
        active: false,
        actions: true,
    });
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [primaryAccount, setPrimaryAccount] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);
    const navigate = useNavigate();



    useEffect(() => {
        const storedFilterOptions = sessionStorage.getItem('filterOptions');
        const storedFilterText = sessionStorage.getItem('filterText');
        const storedSelectedColumns = sessionStorage.getItem('selectedColumns');

        console.log(`Filter: ${storedFilterText}`); 

        if (storedFilterOptions) {
            setFilterOptions(JSON.parse(storedFilterOptions));
        }
        if (storedFilterText) {
            setFilter(storedFilterText);
        }
        if (storedSelectedColumns) {
            setSelectedColumns(JSON.parse(storedSelectedColumns));
        }

        const fetchAccounts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts`);
                const accountsData = response.data;

                const accountsWithDetails = await Promise.all(accountsData.map(async account => {
                    try {

                    const contactsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/contacts/account/${account._id}`);
                    const assetsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assets?account=${account._id}`);

                        return {
                            ...account,
                            contacts: contactsResponse.data,
                            assets: assetsResponse.data
                        };
                    } catch (error) {
                        console.error('Error fetching contacts and assets:', error);
                        return {
                            ...account,
                            contacts: [],
                            assets: []
                        };
                    }

                }));

                setAccounts(accountsWithDetails);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching accounts:', error);
                setLoading(false);
            }
        };

        fetchAccounts();
    }, []);

    const handleFilterIconClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleColumnsIconClick = (event) => {
        setColumnsAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const handleColumnsClose = () => {
        setColumnsAnchorEl(null);
    };

    const handleFilterChange = (event) => {
        const newFilterOptions = {
            ...filterOptions,
            [event.target.name]: event.target.checked,
        };
        setFilterOptions(newFilterOptions);
        sessionStorage.setItem('filterOptions', JSON.stringify(newFilterOptions));
    };

    const handleColumnChange = (event) => {
        const newSelectedColumns = {
            ...selectedColumns,
            [event.target.name]: event.target.checked,
        };
        setSelectedColumns(newSelectedColumns);
        sessionStorage.setItem('selectedColumns', JSON.stringify(newSelectedColumns));
    };

    const handleFilterChangeText = (event) => {
        const newFilterText = event.target.value;
        setFilter(newFilterText);
        sessionStorage.setItem('filterText', newFilterText);
    };

    const applyFilters = (account) => {
        if (filterOptions.showProblemAccountsOnly && !account.requiresAttention) {
            return false;
        }
        if (filterOptions.hideInactive && !account.active) {
            return false;
        }
        if (filterOptions.hideWithoutAssets && (!account.assets || account.assets.length === 0)) {
            return false;
        }
        if (filterOptions.showOnlyWithoutAssets && account.assets && account.assets.length > 0) {
            return false;
        }
        if (filterOptions.showInvoiced && (!account.assets || !account.assets.some(asset => asset.dueDate))) {
            return false;
        }
        return true;
    };

    const filteredAccounts = accounts.filter(account =>
        applyFilters(account) &&
        (account.name.toLowerCase().includes(filter.toLowerCase()) ||
            (account.contacts && account.contacts.some(contact => contact.email.toLowerCase().includes(filter.toLowerCase()))) ||
            (account.assets && account.assets.some(asset => asset.product.name.toLowerCase().includes(filter.toLowerCase()))))
    );

    const handleSelectAccount = (account) => {
        const newSelectedAccounts = selectedAccounts.includes(account._id)
            ? selectedAccounts.filter(id => id !== account._id)
            : [...selectedAccounts, account._id];
        setSelectedAccounts(newSelectedAccounts);
    };

    const handleOpenMergeDialog = () => {
        setMergeDialogOpen(true);
    };

    const handleCloseMergeDialog = () => {
        setMergeDialogOpen(false);
    };

    const handleMergeAccounts = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/merge`, {
                primaryAccount,
                accountsToMerge: selectedAccounts.filter(id => id !== primaryAccount),
            });
            const updatedAccounts = accounts.filter(account => !selectedAccounts.includes(account._id) || account._id === primaryAccount);
            setAccounts(updatedAccounts);
            setSelectedAccounts([]);
            setPrimaryAccount('');
            handleCloseMergeDialog();
        } catch (error) {
            console.error('Error merging accounts:', error);
        }
    };

    const handleDeleteAccount = async () => {
        if (selectedAccount) {
            try {
                await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${selectedAccount._id}`);
                setAccounts(accounts.filter(account => account._id !== selectedAccount._id));
                setDeleteDialogOpen(false);
                setSelectedAccount(null);
            } catch (error) {
                console.error('Error deleting account:', error);
            }
        }
    };

    const handleToggleActive = async (account) => {
        const updatedAccount = { ...account, active: !account.active };
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${account._id}`, updatedAccount);
            setAccounts(accounts.map(acc => (acc._id === account._id ? updatedAccount : acc)));
        } catch (error) {
            console.error('Error updating account:', error);
        }
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h4" gutterBottom>
                        Accounts
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/new-account')}
                        sx={{ ml: 2 }}
                    >
                        New Account
                    </Button>
                    {selectedAccounts.length >= 2 && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleOpenMergeDialog}
                            sx={{ ml: 2 }}
                        >
                            Merge
                        </Button>
                    )}
                </Box>
                <Box display="flex" alignItems="center" ml="auto">
                    <TextField
                        label="Filter"
                        variant="outlined"
                        size="small"
                        sx={{ width: '250px' }}
                        value={filter}
                        onChange={handleFilterChangeText}
                        InputProps={{
                            endAdornment: filter ? (
                                <IconButton
                                    aria-label="clear filter"
                                    onClick={() => {
                                        setFilter('');
                                        sessionStorage.removeItem('filterText');
                                    }}
                                    edge="end"
                                >
                                    <ClearIcon />
                                </IconButton>
                            ) : null,
                        }}
                    />
                    <IconButton
                        aria-label="filter"
                        onClick={handleFilterIconClick}
                        sx={{ ml: 1 }}
                    >
                        <FilterListIcon />
                    </IconButton>
                    <IconButton
                        aria-label="columns"
                        onClick={handleColumnsIconClick}
                        sx={{ ml: 1 }}
                    >
                        <ViewColumnIcon />
                    </IconButton>
                    <Popover
                        open={Boolean(filterAnchorEl)}
                        anchorEl={filterAnchorEl}
                        onClose={handleFilterClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <AccountFilter
                            filterOptions={filterOptions}
                            handleFilterChange={handleFilterChange}
                        />
                    </Popover>
                    <Popover
                        open={Boolean(columnsAnchorEl)}
                        anchorEl={columnsAnchorEl}
                        onClose={handleColumnsClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <ColumnSelector
                            selectedColumns={selectedColumns}
                            handleColumnChange={handleColumnChange}
                        />
                    </Popover>
                </Box>
            </Box>
            <AccountsTable
                accounts={filteredAccounts}
                loading={loading}
                selectedAccounts={selectedAccounts}
                handleSelectAccount={handleSelectAccount}
                handleToggleActive={handleToggleActive}
                navigate={navigate}
                setDeleteDialogOpen={setDeleteDialogOpen}
                setSelectedAccount={setSelectedAccount}
                setSelectedAccounts={setSelectedAccounts}
                setAccounts={setAccounts}
                selectedColumns={selectedColumns}
            />
            <DeleteDialog
                open={deleteDialogOpen}
                handleClose={() => setDeleteDialogOpen(false)}
                handleDeleteAccount={handleDeleteAccount}
            />
            <MergeDialog
                open={mergeDialogOpen}
                handleClose={handleCloseMergeDialog}
                handleMergeAccounts={handleMergeAccounts}
                primaryAccount={primaryAccount}
                setPrimaryAccount={setPrimaryAccount}
                selectedAccounts={selectedAccounts}
                accounts={accounts}
            />
        </Box>
    );
};

export default Accounts;
