import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, Select, MenuItem } from '@mui/material';

const MergeDialog = ({ open, handleClose, handleMergeAccounts, primaryAccount, setPrimaryAccount, selectedAccounts, accounts }) => (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="merge-dialog-title"
        aria-describedby="merge-dialog-description"
    >
        <DialogTitle id="merge-dialog-title">{"Select the account to retain"}</DialogTitle>
        <DialogContent>
            <FormControl fullWidth>
                <Select
                    value={primaryAccount}
                    onChange={(e) => setPrimaryAccount(e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="" disabled>Select Account</MenuItem>
                    {selectedAccounts.map(id => {
                        const account = accounts.find(acc => acc._id === id);
                        return (
                            <MenuItem key={id} value={id}>
                                {account.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button onClick={handleMergeAccounts} color="secondary" autoFocus>
                Merge
            </Button>
        </DialogActions>
    </Dialog>
);

export default MergeDialog;
