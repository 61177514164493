import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography,
    TableSortLabel, Link, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button,
    TextField, MenuItem, Select, Checkbox, ListItemText, FormControl, InputLabel
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';

const SystemLog = () => {
    const [logs, setLogs] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('timestamp');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedContext, setSelectedContext] = useState('');
    const [users, setUsers] = useState([]);
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        selectedUsers: ['All'],
        selectedObjects: ['All'],
        name: '',
        selectedActions: ['All']
    });

    const objects = ['All', 'Account', 'Opportunity', 'Product', 'Invoice'];
    const actions = ['All', 'CREATE', 'UPDATE', 'DELETE', 'TOGGLE'];

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/actions/last`);
                setLogs(response.data);
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        };

        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users`);
                setUsers(['All', ...response.data.map(user => user.username)]);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchLogs();
        fetchUsers();
    }, []);

    useEffect(() => {
        const today = new Date();
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 7);
        setFilters(prevFilters => ({
            ...prevFilters,
            startDate: pastDate.toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0]
        }));
    }, []);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
    
        setFilters(prevFilters => {
            if (name === 'name') {
                // Directly update the name field as a string
                return {
                    ...prevFilters,
                    [name]: value
                };
            }
    
            let newValue = value ? (Array.isArray(value) ? value : [value]) : [];
    
            if (name === 'selectedUsers' || name === 'selectedObjects' || name === 'selectedActions') {
                // If "All" is selected and another item is clicked, deselect "All"
                if (prevFilters[name].includes('All')) {
                    newValue = newValue.filter(item => item !== 'All');
                }
                // If "All" is clicked, clear other selections
                if (newValue.includes('All')) {
                    return {
                        ...prevFilters,
                        [name]: ['All']
                    };
                } else {
                    return {
                        ...prevFilters,
                        [name]: newValue
                    };
                }
            }
    
            return {
                ...prevFilters,
                [name]: newValue
            };
        });
    };
    
    

    const applyFilters = async () => {
        const params = {
            startDate: filters.startDate,
            endDate: filters.endDate,
            user: filters.selectedUsers.join(','),
            object: filters.selectedObjects.join(','),
            name: filters.name,
            action: filters.selectedActions.join(',')
        };
        
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/actions/search`, { params });
            setLogs(response.data);
        } catch (error) {
            console.error('Error fetching filtered logs:', error);
        }
    };

    const clearFilters = async () => {
        const today = new Date();
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 7);
        
        setFilters({
            startDate: pastDate.toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0],
            selectedUsers: ['All'],
            selectedObjects: ['All'],
            name: '',
            selectedActions: ['All']
        });
    
        try {
            // Make a back-end call to refresh the table with default data
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/actions/last`);
            setLogs(response.data);
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
    };

    const sortedLogs = logs.slice().sort((a, b) => {
        if (orderBy === 'timestamp') {
            return order === 'asc' ? new Date(a.timestamp) - new Date(b.timestamp) : new Date(b.timestamp) - new Date(a.timestamp);
        }
        if (a[orderBy] < b[orderBy]) {
            return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const getLinkPath = (object, id) => {
        switch (object) {
            case 'Account':
                return `/edit-account/${id}`;
            case 'Opportunity':
                return `/edit-opportunity/${id}`;
            case 'Product':
                return `/edit-product/${id}`;
            case 'Invoice':
                return `/edit-invoice/${id}`;
            default:
                return '#';
        }
    };

    const handleViewClick = async (id, timestamp) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/actions/context`, {
                params: { id, timestamp }
            });
            setSelectedContext(response.data.context);
            setDialogOpen(true);
        } catch (error) {
            console.error('Error fetching context:', error);
        }
    };

    const handleClose = () => {
        setDialogOpen(false);
        setSelectedContext('');
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                System Log
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
                <TextField
                    label="Start Date"
                    name="startDate"
                    value={filters.startDate}
                    onChange={handleFilterChange}
                    size="small"
                />
                <TextField
                    label="End Date"
                    name="endDate"
                    value={filters.endDate}
                    onChange={handleFilterChange}
                    size="small"
                />
                <FormControl fullWidth sx={{ width: 200 }} size="small">
                    <InputLabel>Users</InputLabel>
                    <Select
                        name="selectedUsers"
                        multiple
                        value={filters.selectedUsers}
                        onChange={handleFilterChange}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {users.map(user => (
                            <MenuItem key={user} value={user}>
                                <Checkbox checked={filters.selectedUsers.indexOf(user) > -1} />
                                <ListItemText primary={user} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ width: 200 }} size="small">
                    <InputLabel>Objects</InputLabel>
                    <Select
                        name="selectedObjects"
                        multiple
                        value={filters.selectedObjects}
                        onChange={handleFilterChange}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {objects.map(object => (
                            <MenuItem key={object} value={object}>
                                <Checkbox checked={filters.selectedObjects.indexOf(object) > -1} />
                                <ListItemText primary={object} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Name"
                    name="name"
                    value={filters.name}
                    onChange={handleFilterChange}
                    size="small"
                />
                <FormControl fullWidth sx={{ width: 200 }} size="small">
                    <InputLabel>Actions</InputLabel>
                    <Select
                        name="selectedActions"
                        multiple
                        value={filters.selectedActions}
                        onChange={handleFilterChange}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {actions.map(action => (
                            <MenuItem key={action} value={action}>
                                <Checkbox checked={filters.selectedActions.indexOf(action) > -1} />
                                <ListItemText primary={action} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <Button 
        variant="contained" 
        color="primary" 
        onClick={applyFilters} 
        size="medium" // Set size to medium to match the height of the edit fields
        sx={{ height: '40px' }} // Adjust height as necessary to match the text fields
    >
        Apply
    </Button>
    <Button 
        variant="contained" // Make the button filled with color
        color="secondary"
        onClick={clearFilters} 
        sx={{ height: '40px' }}
        size="medium" // Set size to medium to match the height of the edit fields
    >
        Clear
    </Button>
</Box>

            </Box>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'timestamp'}
                                    direction={orderBy === 'timestamp' ? order : 'asc'}
                                    onClick={() => handleRequestSort('timestamp')}
                                >
                                    Timestamp
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'user'}
                                    direction={orderBy === 'user' ? order : 'asc'}
                                    onClick={() => handleRequestSort('user')}
                                >
                                    User
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'object'}
                                    direction={orderBy === 'object' ? order : 'asc'}
                                    onClick={() => handleRequestSort('object')}
                                >
                                    Object
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ display: 'none' }}>
                                ID
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                Name
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'action'}
                                    direction={orderBy === 'action' ? order : 'asc'}
                                    onClick={() => handleRequestSort('action')}
                                >
                                    Action
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                View
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedLogs.map((log, index) => (
                            <TableRow key={index}>
                                <TableCell>{new Date(log.timestamp).toLocaleString()}</TableCell>
                                <TableCell>{log.user}</TableCell>
                                <TableCell>{log.object}</TableCell>
                                <TableCell sx={{ display: 'none' }}>{log.id}</TableCell>
                                <TableCell>
                                    {log.action !== 'DELETE' && ['Account', 'Opportunity', 'Product', 'Invoice'].includes(log.object) ? (
                                        <Link href={getLinkPath(log.object, log.id)}>{log.name}</Link>
                                    ) : (
                                        log.name
                                    )}
                                </TableCell>
                                <TableCell>
    {log.action === 'CREATE' && (
        <>
            <AddCircleIcon sx={{ color: 'lightgreen', verticalAlign: 'middle', marginRight: 0.5 }} />
            CREATE
        </>
    )}
    {log.action === 'UPDATE' && (
        <>
            <EditIcon sx={{ color: 'lightblue', verticalAlign: 'middle', marginRight: 0.5 }} />
            UPDATE
        </>
    )}
    {log.action === 'DELETE' && (
        <>
            <DeleteIcon sx={{ color: 'lightcoral', verticalAlign: 'middle', marginRight: 0.5 }} />
            DELETE
        </>
    )}
    {log.action === 'TOGGLE' && (
        <>
            <SyncIcon sx={{ color: 'orange', verticalAlign: 'middle', marginRight: 0.5 }} />
            TOGGLE
        </>
    )}
</TableCell>

                                <TableCell>
                                    <IconButton onClick={() => handleViewClick(log.id, log.timestamp)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={dialogOpen} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Context</DialogTitle>
                <DialogContent dividers>
                    <Box
                        sx={{
                            height: '400px',
                            overflowY: 'scroll',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            backgroundColor: '#f5f5f5',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <pre>
                            {(() => {
                                try {
                                    const match = selectedContext.match(/(.*?)(\{.*$)/s);
                                    if (match) {
                                        const textPart = match[1];
                                        const jsonPart = JSON.stringify(JSON.parse(match[2]), null, 2);
                                        return `${textPart}\n\n${jsonPart}`;
                                    } else {
                                        return selectedContext;
                                    }
                                } catch (error) {
                                    return selectedContext; // Fallback to plain text if JSON parsing fails
                                }
                            })()}
                        </pre>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default SystemLog;
