import React from 'react';
import { Box, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const AccountFilter = ({ filterOptions, handleFilterChange }) => {
    return (
        <Box p={2}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Filters</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={filterOptions.showProblemAccountsOnly} onChange={handleFilterChange} name="showProblemAccountsOnly" />}
                        label="Show Problem Accounts Only"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={filterOptions.hideInactive} onChange={handleFilterChange} name="hideInactive" />}
                        label="Hide Inactive"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={filterOptions.hideWithoutAssets} onChange={handleFilterChange} name="hideWithoutAssets" />}
                        label="Hide w/o Assets"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={filterOptions.showOnlyWithoutAssets} onChange={handleFilterChange} name="showOnlyWithoutAssets" />}
                        label="Show Only w/o Assets"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={filterOptions.showInvoiced} onChange={handleFilterChange} name="showInvoiced" />}
                        label="Show Invoiced"
                    />
                </FormGroup>
            </FormControl>
        </Box>
    );
};

export default AccountFilter;
