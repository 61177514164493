import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@mui/icons-material';
import axios from 'axios';

const PaymentVerification = () => {
    const [email, setEmail] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setTransactions([]); // Clear previous transactions
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/braintree/payments`, { email });
            setTransactions(response.data);
        } catch (error) {
            console.error('Error fetching transactions', error);
            setError('Failed to fetch transactions. Please check the email and try again.');
        }
    };

    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" gutterBottom>
                    Payment Verification
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            margin="normal"
                            required
                            sx={{ width: '450px', mr: 2 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </form>
                {error && (
                    <Typography variant="body1" color="error" mt={2}>
                        {error}
                    </Typography>
                )}
                {transactions.length > 0 && (
                    <Box mt={4}>
                        <Typography variant="h6" gutterBottom>
                            Recent Transactions
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Date</b></TableCell>
                                        <TableCell><b>Amount</b></TableCell>
                                        <TableCell><b>Status</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions.map((transaction, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{new Date(transaction.date).toLocaleString()}</TableCell>
                                            <TableCell>{`$${transaction.amount}`}</TableCell>
                                            <TableCell>
                                                {transaction.status === 'settled' ? (
                                                    <Box display="flex" alignItems="center">
                                                        <CheckCircleIcon style={{ color: 'green' }} />
                                                        <span style={{ marginLeft: '8px' }}>{transaction.status}</span>
                                                    </Box>
                                                ) : (
                                                    <Box display="flex" alignItems="center">
                                                        <CancelIcon style={{ color: 'red' }} />
                                                        <span style={{ marginLeft: '8px' }}>{transaction.status}</span>
                                                    </Box>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default PaymentVerification;
