import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Button, List, ListItem, ListItemText, Paper } from '@mui/material';

const Reports = () => {
    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Reports
            </Typography>
            <Paper style={{ padding: '16px' }}>
                <List>
                    <ListItem component={Link} to="/reports/current-month-forecast">
                        <ListItemText primary="Current Month Forecast" />
                    </ListItem>
                    <ListItem component={Link} to="/reports/accounts-with-selected-products">
                        <ListItemText primary="Accounts with Selected Products" />
                    </ListItem>
                </List>
            </Paper>
        </Box>
    );
};

export default Reports;
