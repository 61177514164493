import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/users/${id}`);
            fetchUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleToggleActive = async (id, isActive) => {
        try {
            if (isActive) {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/${id}/inactive`);
            } else {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/${id}/active`);
            }
            fetchUsers();
        } catch (error) {
            console.error('Error toggling user active status:', error);
        }
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        setSelectedGroup(user.group || 'User'); // Default to 'User' if group is not set
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedUser(null);
    };

    const handleGroupChange = async () => {
        if (selectedUser) {
            try {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/${selectedUser._id}/group`, { group: selectedGroup });
                fetchUsers();
                handleClose();
            } catch (error) {
                console.error('Error updating user group:', error);
            }
        }
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Users
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Username</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>First Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Last Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Creation Date</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Group</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user._id}>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.firstName}</TableCell>
                                <TableCell>{user.lastName}</TableCell>
                                <TableCell>{new Date(user.creationDate).toLocaleDateString()}</TableCell>
                                <TableCell>
                                    <Switch
                                        checked={user.active}
                                        onChange={() => handleToggleActive(user._id, user.active)}
                                        color="primary"
                                    />
                                </TableCell>
                                <TableCell>{user.group || 'User'}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEdit(user)} >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(user._id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit User Group</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select a group for the user.
                    </DialogContentText>
                    <FormControl fullWidth sx={{marginTop: 2}}>
                        
                        <Select
                            
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                            size="small"
                            sx={{ height: 36 }} // Adjust the height as needed
                        >
                            <MenuItem value="User">User</MenuItem>
                            <MenuItem value="Admin">Admin</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleGroupChange} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Users;
