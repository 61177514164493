import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';

const AssetTable = ({ assets, onDelete, accountId }) => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [selectedAssetId, setSelectedAssetId] = React.useState(null);

    const handleDeleteClick = (id) => {
        setSelectedAssetId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedAssetId(null);
    };

    const handleDeleteConfirm = () => {
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/assets/${selectedAssetId}`).then(() => {
            onDelete(selectedAssetId);
            handleClose();
        });
    };

    const handleNewAsset = () => {
        navigate(`/asset/new?accountId=${accountId}`);
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-CA', options); // 'en-CA' gives 'YYYY-MM-DD' format
    };

    const formatCurrency = (value) => {
        const roundedValue = Math.round(value);
        return roundedValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    };

    const formatNumber = (value) => {
        return value.toLocaleString('en-US');
    };

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Assets
            </Typography>
            <Button variant="contained" color="primary" onClick={handleNewAsset}>
                New Asset
            </Button>
            <TableContainer component={Paper} style={{ marginTop: 16 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Sale Price</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Frequency</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Billing</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Due Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Limit</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assets.map((asset) => (
                            <TableRow key={asset._id}>
                                <TableCell style={{ color: asset.active ? 'inherit' : 'gray' }}>{asset.product.name}</TableCell>
                                <TableCell style={{ color: asset.active ? 'inherit' : 'gray' }}>{formatCurrency(asset.salePrice)}</TableCell>
                                <TableCell style={{ color: asset.active ? 'inherit' : 'gray' }}>{asset.frequency}</TableCell>
                                <TableCell style={{ color: asset.active ? 'inherit' : 'gray' }}>{asset.billing}</TableCell>
                                <TableCell style={{ color: asset.active ? 'inherit' : 'gray' }}>{formatDate(asset.dueDate)}</TableCell>
                                <TableCell style={{ color: asset.active ? 'inherit' : 'gray' }}>{asset.usageLimit !== undefined ? formatNumber(asset.usageLimit) : ''}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => navigate(`/asset/${asset._id}`)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(asset._id)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this asset? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AssetTable;
