import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    Typography,
    Container,
    Grid,
    Paper,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';

const AssetForm = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [asset, setAsset] = useState({
        product: '',
        saleDate: new Date().toISOString().split('T')[0],  // Set default saleDate to today's date
        salePrice: '',
        active: true,
        frequency: 'None',
        billing: 'Credit Card',  // Set default billing method
        account: '',  // Ensure account is initialized
        usageLimit: 0,
        dueDate: '',
        terms: 'None'
    });
    const [account, setAccount] = useState({});
    const [products, setProducts] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const accountId = query.get('accountId');
        if (id !== 'new') {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assets/${id}`).then((response) => {
                const assetData = response.data;
                setAsset({
                    ...assetData,
                    product: assetData.product._id,  // Ensure product is set as ObjectId
                });
                return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${assetData.account._id}`);
            }).then((response) => {
                setAccount(response.data);
            }).catch(error => {
                console.error("Error fetching asset or account data:", error);
            });
        } else if (accountId) {
            setAsset((prevAsset) => ({
                ...prevAsset,
                account: accountId
            }));
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${accountId}`).then((response) => {
                setAccount(response.data);
            }).catch(error => {
                console.error("Error fetching account data:", error);
            });
        }
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`).then((response) => {
            setProducts(response.data);
        }).catch(error => {
            console.error("Error fetching products:", error);
        });
    }, [id, location.search]);

    const validateFields = () => {
        let tempErrors = {};
        if (!asset.product) tempErrors.product = 'Product is required';
        if (!asset.salePrice) tempErrors.salePrice = 'Sale Price is required';
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'product') {
            const selectedProduct = products.find(product => product._id === value);
            if (selectedProduct) {
                setAsset((prevAsset) => ({
                    ...prevAsset,
                    [name]: value,
                    salePrice: selectedProduct.listPrice  // Automatically set salePrice to listPrice of the selected product
                }));
                return;
            }
        }
        setAsset((prevAsset) => ({
            ...prevAsset,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setAsset((prevAsset) => ({
            ...prevAsset,
            [name]: checked,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateFields()) {
            // Remove spaces, dollar sign and commas from salePrice
            const cleanedSalePrice = String(asset.salePrice).replace(/[\s$,]/g, '');
            const updatedAsset = { ...asset, salePrice: cleanedSalePrice };

            const method = id === 'new' ? 'post' : 'put';
            const url = id === 'new' ? `${process.env.REACT_APP_BACKEND_URL}/api/assets` : `${process.env.REACT_APP_BACKEND_URL}/api/assets/${id}`;
            axios[method](url, updatedAsset).then(() => {
                navigate(-1); // Go back to the previous page (Account page)
            }).catch(error => {
                console.error(`Error ${id === 'new' ? 'creating' : 'updating'} asset:`, error);
            });
        }
    };

    const handleCancel = () => {
        navigate(-1); // Go back to the previous page (Account page)
    };

    return (
        <Container component="main" maxWidth="md">
            <Paper style={{ padding: 16 }}>
                <Typography variant="h6" gutterBottom>
                    {id === 'new' ? 'New Asset' : 'Edit Asset'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Account"
                                value={account.name || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.product}>
                                <InputLabel required>Product</InputLabel>
                                <Select
                                    name="product"
                                    value={asset.product}
                                    onChange={handleChange}
                                >
                                    {products.map((product) => (
                                        <MenuItem key={product._id} value={product._id}>
                                            {product.name} - ${product.listPrice}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.product && <Typography color="error">{errors.product}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                name="saleDate"
                                label="Sale Date"
                                type="text"
                                value={asset.saleDate}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors.saleDate}
                                helperText={errors.saleDate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                
                                name="dueDate"
                                label="Due Date"
                                type="text"
                                value={asset.dueDate}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors.dueDate}
                                helperText={errors.dueDate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                name="salePrice"
                                label="Sale Price"
                                value={asset.salePrice}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.salePrice}
                                helperText={errors.salePrice}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth error={!!errors.terms}>
                                <InputLabel required>Terms</InputLabel>
                                <Select
                                    name="terms"
                                    value={asset.terms}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="NET30">NET30</MenuItem>
                                    <MenuItem value="NET60">NET60</MenuItem>
                                    <MenuItem value="NET90">NET90</MenuItem>
                                </Select>
                                {errors.terms && <Typography color="error">{errors.terms}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth error={!!errors.frequency}>
                                <InputLabel required>Frequency</InputLabel>
                                <Select
                                    name="frequency"
                                    value={asset.frequency}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="Annual">Annual</MenuItem>
                                    <MenuItem value="2-Years">2-Years</MenuItem>
                                    <MenuItem value="3-Years">3-Years</MenuItem>
                                </Select>
                                {errors.frequency && <Typography color="error">{errors.frequency}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth error={!!errors.billing}>
                                <InputLabel required>Billing</InputLabel>
                                <Select
                                    name="billing"
                                    value={asset.billing}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="Credit Card">Credit Card</MenuItem>
                                    <MenuItem value="Invoice ACH">Invoice ACH</MenuItem>
                                    <MenuItem value="Invoice Check">Invoice Check</MenuItem>
                                </Select>
                                {errors.billing && <Typography color="error">{errors.billing}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary" align="left">
                                Due date and terms are only applicable for ACH Invoice billing. Leave blank otherwise.
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="usageLimit"
                                label="Contractual Usage Limit per Month"
                                type="number"
                                value={asset.usageLimit}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="active"
                                        checked={asset.active}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
};

export default AssetForm;

