import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Grid, Paper, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';
//import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Editor } from '@tinymce/tinymce-react';

const ActivityTable = ({ accountId }) => {
    const [activities, setActivities] = useState([]);
    const [newActivity, setNewActivity] = useState({ comment: '' });
    const [editActivity, setEditActivity] = useState({ id: null, comment: '' });
    const [user, setUser] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/activities/account/${accountId}`);
                setActivities(response.data.sort((a, b) => new Date(b.created) - new Date(a.created)));
            } catch (error) {
                console.error('Error fetching activities:', error);
            }
        };

        fetchActivities();
    }, [accountId]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUser(storedUser);
        }
    }, []);

    const handleEditorChange = (content) => {
        setNewActivity((prevActivity) => ({
            ...prevActivity,
            comment: content,
        }));
    };

    const handleEditEditorChange = (content) => {
        setEditActivity((prevActivity) => ({
            ...prevActivity,
            comment: content,
        }));
    };

    const handleSaveActivity = async () => {
        if (newActivity.comment.trim() === '') {
            alert('Comment cannot be empty');
            return;
        }

        try {
            const activityData = {
                ...newActivity,
                author: user ? user.userId : null, // Use the actual user ID from localStorage
                account: accountId,
            };
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/activities`, activityData);
            const savedActivity = response.data;
            savedActivity.author = {
                _id: user.userId,
                firstName: user.firstName,
                lastName: user.lastName,
            };
            setActivities([savedActivity, ...activities]);
            setNewActivity({ comment: '' });
        } catch (error) {
            console.error('Error saving activity:', error);
        }
    };

    const handleEditClick = (activity) => {
        setEditActivity({ id: activity._id, comment: activity.comment });
        setOpenEdit(true);
    };

    const handleEditSave = async () => {
        if (editActivity.comment.trim() === '') {
            alert('Comment cannot be empty');
            return;
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${editActivity.id}`, { comment: editActivity.comment });
            const updatedActivity = response.data;
            setActivities((prevActivities) =>
                prevActivities.map((activity) => (activity._id === updatedActivity._id ? updatedActivity : activity))
            );
            setOpenEdit(false);
            setEditActivity({ id: null, comment: '' });
        } catch (error) {
            console.error('Error updating activity:', error);
        }
    };

    const handleEditCancel = () => {
        setOpenEdit(false);
        setEditActivity({ id: null, comment: '' });
    };

    const handleDeleteClick = (activity) => {
        setSelectedActivity(activity);
        setOpenDelete(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${selectedActivity._id}`);
            setActivities((prevActivities) => prevActivities.filter((activity) => activity._id !== selectedActivity._id));
            setOpenDelete(false);
            setSelectedActivity(null);
        } catch (error) {
            console.error('Error deleting activity:', error);
        }
    };

    const handleDeleteCancel = () => {
        setOpenDelete(false);
        setSelectedActivity(null);
    };

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Activities
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                        value={newActivity.comment}
                        init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                        }}
                        onEditorChange={handleEditorChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleSaveActivity}>
                        Save Activity
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 16 }}>
                {activities.map((activity) => (
                    <Grid item xs={12} key={activity._id}>
                        <Paper style={{ padding: 16, position: 'relative' }}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                {new Date(activity.created).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' })} - {activity.author.firstName} {activity.author.lastName}
                            </Typography>
                            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: activity.comment }} />
                            <Box style={{ position: 'absolute', top: 16, right: 16 }}>
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => handleEditClick(activity)}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => handleDeleteClick(activity)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Paper>
                    </Grid>
                ))} 
            </Grid>
            <Dialog
                open={openDelete}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Activity"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this activity?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openEdit}
                onClose={handleEditCancel}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Edit Activity</DialogTitle>
                <DialogContent>
                    <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                        value={editActivity.comment}
                        init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                        }}
                        onEditorChange={handleEditEditorChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ActivityTable;
