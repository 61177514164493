import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Paper, Divider } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';

const SearchResults = ({ results }) => {
    return (
        <Box mt={4} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5" gutterBottom>
                Search Results ({results.length} items found)
            </Typography>
            <Divider sx={{ width: '100%', mb: 3 }} />
            {results.length === 0 ? (
                <Typography variant="body1" sx={{ mt: 2 }}>
                    No results found.
                </Typography>
            ) : (
                <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    {results.map((result, index) => (
                        <Paper
                            elevation={2}
                            sx={{ padding: 2, mb: 2, width: '300px', margin: '10px' }}
                            key={index}
                        >
                            <Box display="flex" alignItems="center" mb={1}>
                                <Box mr={2}>
                                    {result.type === 'Account' && <BusinessIcon />}
                                    {result.type === 'Product' && <ShoppingCartIcon />}
                                    {result.type === 'Contact' && <AccountCircleIcon />}
                                    {result.type === 'User' && <PersonIcon />}
                                </Box>
                                <Box flexGrow={1}>
                                    {result.type === 'Account' && (
                                        <>
                                            <Typography variant="subtitle1">
                                                <Link to={`/edit-account/${result._id}`}>{result.name}</Link>
                                            </Typography>
                                            <Typography variant="body2">
                                                Created: {new Date(result.created).toLocaleDateString()}
                                            </Typography>
                                            <Typography variant="body2">
                                                Contacts: {result.contacts.join(', ')}
                                            </Typography>
                                        </>
                                    )}
                                    {result.type === 'Product' && (
                                        <>
                                            <Typography variant="subtitle1">
                                                <Link to={`/edit-product/${result._id}`}>{result.name}</Link>
                                            </Typography>
                                            <Typography variant="body2">
                                                List Price: ${result.listPrice || 'N/A'}
                                            </Typography>
                                            <Typography variant="body2">
                                                Type: {result.productType || 'N/A'}
                                            </Typography>
                                        </>
                                    )}
                                    {result.type === 'Contact' && (
                                        <>
                                            <Typography variant="subtitle1">
                                                <Link to={`/edit-account/${result.accountId}`}>{result.accountName}</Link> - {result.firstName} {result.lastName}
                                            </Typography>
                                            <Typography variant="body2">
                                                Email: {result.email}
                                            </Typography>
                                            <Typography variant="body2">
                                                Created: {new Date(result.created).toLocaleDateString()}
                                            </Typography>
                                        </>
                                    )}
                                    {result.type === 'User' && (
                                        <>
                                            <Typography variant="subtitle1">
                                                {result.firstName} {result.lastName}
                                            </Typography>
                                            <Typography variant="body2">
                                                Email: {result.email}
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Paper>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default SearchResults;
