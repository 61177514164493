import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    Typography,
    Container,
    Grid,
    Paper,
    Switch,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AssetTable from './Account/AssetTable';
import ContactTable from './Account/ContactTable';
import ActivityTable from './Account/ActivityTable';

const AccountForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [account, setAccount] = useState({
        name: '',
        created: '',
        active: true,
        requiresAttention: false,
        source: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone: '',
    });
    const [assets, setAssets] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false); // State to handle submission

    useEffect(() => {
        if (id) {
            // Check if the account has been merged
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/merged-accounts/${id}`)
                .then((response) => {
                    if (response.data.primaryAccountId) {
                        navigate(`/edit-account/${response.data.primaryAccountId}`);
                    } else {
                        // Proceed to load the account data
                        loadAccountData();
                    }
                })
                .catch((error) => {
                    console.error('Error checking merged accounts:', error);
                    loadAccountData(); // Load the account data if no merge found
                });
        }
    }, [id]);

    const loadAccountData = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${id}`).then((response) => {
            setAccount({
                ...response.data,
                created: formatDateTimeLocal(response.data.created),
            });
        });
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assets?account=${id}`).then((response) => {
            setAssets(response.data);
        });
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/contacts/account/${id}`).then((response) => {
            setContacts(response.data);
        });
    };

    useEffect(() => {
        if (!account.created) {
            setAccount((prevAccount) => ({
                ...prevAccount,
                created: new Date().toISOString().slice(0, 16),
            }));
        }
    }, [account.created]);

    const validateFields = () => {
        let tempErrors = {};
        if (!account.name) tempErrors.name = 'Name is required';
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAccount((prevAccount) => ({
            ...prevAccount,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setAccount((prevAccount) => ({
            ...prevAccount,
            [name]: checked,
        }));
    };

    const handleSubmit = async (e, action) => {
        e.preventDefault();
        if (validateFields() && !isSubmitting) {
            setIsSubmitting(true); // Disable the button after the first click
            try {
                const accountData = {
                    ...account,
                    created: new Date(account.created).toISOString(),
                };

                let savedAccount;
                if (id) {
                    await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${id}`, accountData);
                    savedAccount = { _id: id };
                } else {
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/accounts`, accountData);
                    savedAccount = response.data;
                }

                if (action === 'close') {
                    navigate('/accounts');
                } else if (action === 'stay') {
                    navigate(`/edit-account/${savedAccount._id}`);
                }
            } catch (error) {
                console.error('Error saving account:', error);
            } finally {
                setIsSubmitting(false); // Re-enable the button after the request is complete
            }
        }
    };

    const handleCancel = () => {
        navigate('/accounts');
    };

    const handleDeleteAsset = (assetId) => {
        setAssets((prevAssets) => prevAssets.filter((asset) => asset._id !== assetId));
    };

    const handleDeleteContact = (contactId) => {
        setContacts((prevContacts) => prevContacts.filter((contact) => contact._id !== contactId));
    };

    const formatDateTimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    return (
        <Container component="main" style={{ maxWidth: '100%' }}>
            <Paper style={{ padding: 16, position: 'relative' }}>
                <IconButton
                    aria-label="close"
                    onClick={handleCancel}
                    style={{ position: 'absolute', right: 16, top: 16 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" gutterBottom>
                    {id ? 'Edit Account' : 'Create Account'}
                </Typography>
                <form onSubmit={(e) => handleSubmit(e, 'close')}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        name="name"
                                        label="Name"
                                        value={account.name}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.name}
                                        helperText={errors.name}
                                        disabled={isSubmitting} // Disable during submission
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="created"
                                        label="Created"
                                        type="datetime-local"
                                        value={account.created}
                                        onChange={handleChange}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={isSubmitting} // Disable during submission
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="active"
                                                checked={account.active}
                                                onChange={handleCheckboxChange}
                                                disabled={isSubmitting} // Disable during submission
                                            />
                                        }
                                        label="Active"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="requiresAttention"
                                                checked={account.requiresAttention}
                                                onChange={handleCheckboxChange}
                                                disabled={isSubmitting} // Disable during submission
                                                sx={{
                                                    '& .MuiSwitch-track': {
                                                        backgroundColor: account.requiresAttention ? 'red' : 'gray',
                                                    },
                                                    '& .Mui-checked': {
                                                        color: 'red',
                                                    },
                                                    '& .Mui-checked + .MuiSwitch-track': {
                                                        backgroundColor: 'red',
                                                    },
                                                }}
                                            />
                                        }
                                        label="Requires Attention"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="address"
                                        label="Address"
                                        value={account.address}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={isSubmitting} // Disable during submission
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="address2"
                                        label="Address 2"
                                        value={account.address2}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={isSubmitting} // Disable during submission
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        name="city"
                                        label="City"
                                        value={account.city}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={isSubmitting} // Disable during submission
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField
                                        name="state"
                                        label="State"
                                        value={account.state}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={isSubmitting} // Disable during submission
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="zip"
                                        label="Zip"
                                        value={account.zip}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={isSubmitting} // Disable during submission
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField
                                        name="country"
                                        label="Country"
                                        value={account.country}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={isSubmitting} // Disable during submission
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        name="phone"
                                        label="Phone"
                                        value={account.phone}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={isSubmitting} // Disable during submission
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {id && (
                            <>
                                <Grid item xs={12}>
                                    <AssetTable assets={assets} onDelete={handleDeleteAsset} accountId={id} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ContactTable contacts={contacts} onDelete={handleDeleteContact} accountId={id} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ActivityTable accountId={id} />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={4}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={!account.name || isSubmitting} // Disable during submission or if name is empty
                                onClick={(e) => handleSubmit(e, 'close')}
                            >
                                Save and Close
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={!account.name || isSubmitting} // Disable during submission or if name is empty
                                onClick={(e) => handleSubmit(e, 'stay')}
                            >
                                Save and Stay
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={handleCancel}
                                disabled={isSubmitting} // Disable during submission
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
};

export default AccountForm;
