import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, Navigate, Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Box, IconButton, InputBase, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { googleLogout } from '@react-oauth/google';
import Accounts from './components/Accounts';
import AssetForm from './components/Account/AssetForm';
import Products from './components/Products';
import Users from './components/Users';
import Invoices from './components/Invoices';
import InvoiceForm from './components/InvoiceForm';
import AccountForm from './components/AccountForm';
import ProductForm from './components/ProductForm';
import Login from './components/Login';
import ContactForm from './components/Account/ContactForm';
import Reports from './components/Reports';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import axios from 'axios';  // Import axios
import CurrentMonthForecast from './components/Reports/CurrentMonthForecast';
import AccountProducts from './components/Reports/AccountProducts';
import Opportunities from './components/Opportunities';
import OpportunityForm from './components/OpportunityForm';
import SearchResults from './components/SearchResults'; // Import SearchResults
import PaymentVerification from './components/PaymentVerification';
import FailedTransactions from './components/FailedTransactions';
import SystemLog from './components/SystemLog';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ErrorIcon from '@mui/icons-material/Error';
import EventNoteIcon from '@mui/icons-material/EventNote'; // Import the icon 

const App = () => {
  const [user, setUser] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const searchBoxRef = useRef(null);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        const loggedInUser = JSON.parse(localStorage.getItem('user'));
        if (loggedInUser) {
          config.headers.User = loggedInUser.email;
          config.headers.UserId = loggedInUser.userId;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  },
    []);

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    if (loggedInUser) {
      setUser(loggedInUser);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
        setSearchOpen(false);
      }
    };

    if (searchOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchOpen]);

  const handleLogout = () => {
    googleLogout();
    setUser(null);
    localStorage.removeItem('user');
    window.location.href = '/login';  // This ensures navigation to login after logout
  };

  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
    if (searchOpen) {
      setSearchQuery('');
      setSearchResults([]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/search`, {
        params: { query: searchQuery },
      });
      setSearchResults(response.data);
      navigate('/search');
    } catch (error) {
      console.error('Error during search:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/accounts">
          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
          <ListItemText primary="Accounts" />
        </ListItem>
        <ListItem button component={Link} to="/products">
          <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
          <ListItemText primary="Products" />
        </ListItem>
        <ListItem button component={Link} to="/invoices">
          <ListItemIcon><ReceiptIcon /></ListItemIcon>
          <ListItemText primary="Invoices" />
        </ListItem>
        <ListItem button component={Link} to="/opportunities">
          <ListItemIcon><AssignmentIcon /></ListItemIcon>
          <ListItemText primary="Opportunities" />
        </ListItem>
        <ListItem button component={Link} to="/payment-verification">
          <ListItemIcon>
            <VerifiedUserIcon />  {/* You can choose an appropriate icon */}
          </ListItemIcon>
          <ListItemText primary="Payment Verification" />
        </ListItem>
        <ListItem button component={Link} to="/failed-transactions">
          <ListItemIcon>
            <ErrorIcon />  {/* You can choose an appropriate icon */}
          </ListItemIcon>
          <ListItemText primary="Failed Transactions" />
        </ListItem>
        {user?.group === 'Admin' && (
          <>
            <ListItem button component={Link} to="/reports">
              <ListItemIcon><BarChartIcon /></ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem>
            <ListItem button component={Link} to="/users">
              <ListItemIcon><PeopleIcon /></ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
            <ListItem button component={Link} to="/system-log">
              <ListItemIcon><EventNoteIcon /></ListItemIcon>
              <ListItemText primary="System Log" />
            </ListItem>
          </>
        )}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary="Log Off" />
        </ListItem>
      </List>
    </Box>
  );


  if (!user) {
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              HIPAASpace Sales CRM
            </Typography>
          </Toolbar>
        </AppBar>
        <Login setUser={setUser} />;
      </div>
    );
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            HIPAASpace Sales CRM : {user.firstName} {user.lastName}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }} ref={searchBoxRef}>
            {searchOpen ? (
              <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', borderRadius: 1, px: 1 }}>
                <InputBase
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                  sx={{ ml: 1, flex: 1 }}
                />
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
                <IconButton onClick={handleSearchClick}>
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <IconButton onClick={handleSearchClick} sx={{ color: 'white' }}>
                <SearchIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawer}
      </Drawer>
      <Container maxWidth={false} disableGutters sx={{ width: '100%', padding: '20px' }}>
        <Box my={4}>
          <Routes>
            <Route path="/" element={<Navigate to="/accounts" />} />
            <Route path="/login" element={<Login setUser={setUser} />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/products" element={<Products />} />
            <Route path="/users" element={<Users />} />
            <Route path="/new-account" element={<AccountForm />} />
            <Route path="/edit-account/:id" element={<AccountForm />} />
            <Route path="/new-product" element={<ProductForm />} />
            <Route path="/edit-product/:id" element={<ProductForm />} />
            <Route path="/asset/:id" element={<AssetForm />} />
            <Route path="/contact/:id" element={<ContactForm />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/search" element={<SearchResults results={searchResults} />} />
            <Route path="/reports/current-month-forecast" element={<CurrentMonthForecast />} />
            <Route path="/reports/accounts-with-selected-products" element={<AccountProducts />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/new-invoice" element={<InvoiceForm />} />
            <Route path="/edit-invoice/:id" element={<InvoiceForm />} />
            <Route path="/opportunities" element={<Opportunities />} />
            <Route path="/new-opportunity" element={<OpportunityForm />} />
            <Route path="/edit-opportunity/:id" element={<OpportunityForm />} />
            <Route path="/payment-verification" element={<PaymentVerification />} />
            <Route path="/failed-transactions" element={<FailedTransactions />} />
            <Route path="/system-log" element={<SystemLog />} />
          </Routes>
        </Box>
      </Container>
    </div>
  );
};

export default App;
