import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
    TextField,
    Button,
    Typography,
    Container,
    Grid,
    Paper,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Checkbox,
    ListItemText,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import ItemTable from './Invoice/ItemTable';

const InvoiceForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [invoice, setInvoice] = useState({
        notes: '',
        internalNotes: '',
        account: '',
        poNumber: '',
        invoiceDate: '',
        invoiceDueDate: '',
        terms: 'NET30',
        state: 'Draft',
        type: 'Invoice',
        recurrence: 'None',
        items: [],
        total: 0,
        contacts: [],
    });
    const [accounts, setAccounts] = useState([]);
    const [products, setProducts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission

    useEffect(() => {
        const fetchAccounts = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts`);
            setAccounts(response.data);
        };

        const fetchProducts = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`);
            setProducts(response.data);
        };

        const fetchInvoice = async () => {
            if (id !== 'new') {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/invoices/${id}`);
                const invoiceData = response.data;
                setInvoice({
                    ...invoiceData,
                    account: invoiceData.account._id,
                    invoiceDate: invoiceData.invoiceDate.split('T')[0], // No conversion
                    invoiceDueDate: invoiceData.invoiceDueDate.split('T')[0], // No conversion
                    total: invoiceData.total,
                    contacts: invoiceData.contacts,
                    notes: invoiceData.notes, // Set notes from response
                    internalNotes: invoiceData.internalNotes, // Set notes from response
                });

                // Fetch contacts based on the account of the invoice
                fetchContacts(invoiceData.account._id);
            } else {
                const initialInvoiceDate = new Date();
                let dueDate = new Date(initialInvoiceDate);
                dueDate.setDate(initialInvoiceDate.getDate() + parseInt(invoice.terms.replace('NET', '')));
                setInvoice((prevInvoice) => ({
                    ...prevInvoice,
                    invoiceDate: initialInvoiceDate.toISOString().split('T')[0],
                    invoiceDueDate: dueDate.toISOString().split('T')[0]
                }));
            }
        };

        const fetchContacts = async (accountId) => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/contacts/account/${accountId}`);
            setContacts(response.data);
            setFilteredContacts(response.data);
        };

        fetchAccounts();
        fetchProducts();
        fetchInvoice();
    }, [id]);

    useEffect(() => {
        if (invoice.account) {
            const fetchFilteredContacts = async () => {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/contacts/account/${invoice.account}`);
                setFilteredContacts(response.data);
            };
            fetchFilteredContacts();
        } else {
            setFilteredContacts([]);
        }
    }, [invoice.account]);

    const validateFields = () => {
        let tempErrors = {};
        if (!invoice.account) tempErrors.account = 'Account is required';
        if (!invoice.invoiceDate) tempErrors.invoiceDate = 'Invoice Date is required';
        if (!invoice.invoiceDueDate) tempErrors.invoiceDueDate = 'Due Date is required';
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInvoice((prevInvoice) => {
            let newState = { ...prevInvoice, [name]: value };

            if (name === 'recurrence') {
                if (value === 'Monthly') {
                    newState.state = 'Recurrent';
                } else if (value === 'None') {
                    newState.state = 'Draft';
                }
            }

            return newState;
        });
    };

    const handleItemsChange = (updatedItems) => {
        const total = updatedItems.reduce((sum, item) => sum + item.total, 0);
        setInvoice((prevInvoice) => ({
            ...prevInvoice,
            items: updatedItems,
            total,
        }));
    };

    const handleNotesChange = (content) => {
        setInvoice((prevInvoice) => ({
            ...prevInvoice,
            internalNotes: content,
        }));
    };

    const handleSubmit = async (e, preview = false) => {
        e.preventDefault();
        if (validateFields() && !isSubmitting) {
            setIsSubmitting(true); // Disable the button after the first click
            try {
                const invoiceToSave = {
                    ...invoice,
                    invoiceDate: new Date(invoice.invoiceDate).toISOString(),
                    invoiceDueDate: new Date(invoice.invoiceDueDate).toISOString()
                };

                let savedInvoice;
                if (id === 'new') {
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/invoices`, invoiceToSave);
                    savedInvoice = response.data;
                } else {
                    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/invoices/${id}`, invoiceToSave);
                    savedInvoice = response.data;
                }

                if (preview) {
                    const pdfUrl = `${process.env.REACT_APP_BACKEND_URL}/api/invoices/pdf/${savedInvoice._id}`;
                    window.open(pdfUrl, '_blank');
                } else {
                    navigate('/invoices');
                }
            } catch (error) {
                console.error('Error saving invoice:', error);
            } finally {
                setIsSubmitting(false); // Re-enable the button after the request is complete
            }
        }
    };

    const handleCancel = () => {
        navigate('/invoices');
    };

    return (
        <Container component="main" maxWidth="md">
            <Paper style={{ padding: 16 }}>
                <Typography variant="h6" gutterBottom>
                    {id === 'new' ? 'New Invoice' : 'Edit Invoice'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.account}>
                                <InputLabel required>Account</InputLabel>
                                <Select
                                    name="account"
                                    value={invoice.account}
                                    onChange={handleChange}
                                    disabled={isSubmitting} // Disable during submission
                                >
                                    {accounts.map((account) => (
                                        <MenuItem key={account._id} value={account._id}>
                                            {account.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.account && <Typography color="error">{errors.account}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Contacts</InputLabel>
                                <Select
                                    name="contacts"
                                    multiple
                                    value={invoice.contacts}
                                    onChange={handleChange}
                                    disabled={isSubmitting} // Disable during submission
                                    renderValue={(selected) => selected.map(id => {
                                        const contact = filteredContacts.find(c => c._id === id);
                                        return contact ? `${contact.firstName} ${contact.lastName} <${contact.email}>` : '';
                                    }).join(', ')}
                                >
                                    {filteredContacts.map((contact) => (
                                        <MenuItem key={contact._id} value={contact._id}>
                                            <Checkbox checked={invoice.contacts.includes(contact._id)} />
                                            <ListItemText primary={`${contact.firstName} ${contact.lastName} <${contact.email}>`} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="poNumber"
                                label="Invoice PO Number"
                                value={invoice.poNumber}
                                onChange={handleChange}
                                fullWidth
                                disabled={isSubmitting} // Disable during submission
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel required>Terms</InputLabel>
                                <Select
                                    name="terms"
                                    value={invoice.terms}
                                    onChange={handleChange}
                                    disabled={isSubmitting} // Disable during submission
                                >
                                    <MenuItem value="NET0">NET0</MenuItem>
                                    <MenuItem value="NET30">NET30</MenuItem>
                                    <MenuItem value="NET60">NET60</MenuItem>
                                    <MenuItem value="NET90">NET90</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                name="invoiceDate"
                                label="Invoice Date"
                                type="date"
                                value={invoice.invoiceDate}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.invoiceDate}
                                helperText={errors.invoiceDate}
                                disabled={isSubmitting} // Disable during submission
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                name="invoiceDueDate"
                                label="Due Date"
                                type="date"
                                value={invoice.invoiceDueDate}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.invoiceDueDate}
                                helperText={errors.invoiceDueDate}
                                disabled={isSubmitting} // Disable during submission
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel required>State</InputLabel>
                                <Select
                                    name="state"
                                    value={invoice.state}
                                    onChange={handleChange}
                                    disabled={isSubmitting} // Disable during submission
                                >
                                    <MenuItem value="Draft">Draft</MenuItem>
                                    <MenuItem value="Sent">Sent</MenuItem>
                                    <MenuItem value="Paid">Paid</MenuItem>
                                    <MenuItem value="Void">Void</MenuItem>
                                    <MenuItem value="Recurrent">Recurrent</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel required>Type</InputLabel>
                                <Select
                                    name="type"
                                    value={invoice.type}
                                    onChange={handleChange}
                                    disabled={isSubmitting} // Disable during submission
                                >
                                    <MenuItem value="Invoice">Invoice</MenuItem>
                                    <MenuItem value="Quote">Quote</MenuItem>
                                    <MenuItem value="Order">Order</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel required>Recurrence</InputLabel>
                                <Select
                                    name="recurrence"
                                    value={invoice.recurrence}
                                    onChange={handleChange}
                                    disabled={isSubmitting} // Disable during submission
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                </Select>
                            </FormControl>
                            
                        </Grid>    
                        <Grid item xs={12}>
                            <TextField
                                name="notes"
                                label="Invoice Notes"
                                value={invoice.notes}
                                onChange={handleChange}
                                fullWidth
                                disabled={isSubmitting} // Disable during submission
                            />
                        </Grid>                    
                        <Grid item xs={12}>
                            <ItemTable
                                items={invoice.items}
                                onItemsChange={handleItemsChange}
                                products={products}
                                disabled={isSubmitting} // Disable during submission
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                Internal Notes
                            </Typography>
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY} // Replace with your TinyMCE API key
                                value={invoice.internalNotes}
                                init={{
                                    height: 150,
                                    menubar: false,
                                    plugins: [
                                        'autolink',
                                        'advlist lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | link | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat | help'
                                }}
                                //onEditorChange={handleNotesChange}
                                disabled={isSubmitting} // Disable during submission
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={isSubmitting} // Disable during submission
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={(e) => handleSubmit(e, true)}
                                disabled={isSubmitting} // Disable during submission
                            >
                                Save and Preview
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={handleCancel}
                                disabled={isSubmitting} // Disable during submission
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
};

export default InvoiceForm;
