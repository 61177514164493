import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';

const ContactTable = ({ contacts, onDelete, accountId }) => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [selectedContactId, setSelectedContactId] = React.useState(null);

    const handleDeleteClick = (id) => {
        setSelectedContactId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedContactId(null);
    };

    const handleDeleteConfirm = () => {
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/contacts/${selectedContactId}`).then(() => {
            onDelete(selectedContactId);
            handleClose();
        });
    };

    const handleNewContact = () => {
        navigate(`/contact/new?accountId=${accountId}`);
    };

    const filteredContacts = contacts.filter(contact => contact.account === accountId);

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Contacts
            </Typography>
            <Button variant="contained" color="primary" onClick={handleNewContact}>
                New Contact
            </Button>
            <TableContainer component={Paper} style={{ marginTop: 16 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Payment</TableCell> {/* Added column */}
                            <TableCell style={{ fontWeight: 'bold' }}>Title</TableCell> {/* Added column */}
                            <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredContacts.map((contact) => (
                            <TableRow key={contact._id}>
                                <TableCell>{contact.firstName}</TableCell>
                                <TableCell>{contact.lastName}</TableCell>
                                <TableCell>{contact.email}</TableCell>
                                <TableCell>{contact.address} {contact.city} {contact.state} {contact.zip} {contact.country} {contact.phone}</TableCell>
                                <TableCell>{contact.paymentContact ? 'Yes' : 'No'}</TableCell> {/* Added data */}
                                <TableCell>{contact.title}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => navigate(`/contact/${contact._id}`)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(contact._id)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this contact? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ContactTable;
