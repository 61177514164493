import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material';
import { Link } from 'react-router-dom';

const AccountsWithSelectedProducts = () => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [accountCount, setAccountCount] = useState(0);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products`);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        if (selectedProducts.length > 0) {
            const fetchReportData = async () => {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/reports/accounts-with-products`, { products: selectedProducts });
                    setReportData(response.data);

                    const uniqueAccounts = new Set(response.data.map(item => item.accountId));
                    setAccountCount(uniqueAccounts.size);
                } catch (error) {
                    console.error('Error fetching report data:', error);
                }
            };

            fetchReportData();
        }
    }, [selectedProducts]);

    const handleProductChange = (event) => {
        setSelectedProducts(event.target.value);
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const groupByAccount = (data) => {
        return data.reduce((acc, item) => {
            if (!acc[item.accountId]) {
                acc[item.accountId] = {
                    accountName: item.accountName,
                    accountId: item.accountId,
                    assets: [],
                };
            }
            acc[item.accountId].assets.push(item);
            return acc;
        }, {});
    };

    const groupedData = groupByAccount(reportData);

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Accounts with Selected Products
            </Typography>
            <FormControl fullWidth margin="normal">
                <InputLabel>Select Products</InputLabel>
                <Select
                    multiple
                    value={selectedProducts}
                    onChange={handleProductChange}
                >
                    {products.map((product) => (
                        <MenuItem key={product._id} value={product._id}>
                            {product.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TableContainer component={Paper} style={{ marginTop: 16 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Account Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Assets</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Price</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Frequency</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(groupedData).map((group, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Link to={`/edit-account/${group.accountId}`}>
                                        {group.accountName}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {group.assets.map((asset, i) => (
                                        <div key={i}>
                                            {asset.assetName}
                                        </div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {group.assets.map((asset, i) => (
                                        <div key={i}>
                                            {formatCurrency(asset.salePrice)}
                                        </div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {group.assets.map((asset, i) => (
                                        <div key={i}>
                                            {asset.frequency}
                                        </div>
                                    ))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br/>
            <Typography  gutterBottom>
                Total Records: {accountCount}
            </Typography>
        </Box>
    );
};

export default AccountsWithSelectedProducts;
