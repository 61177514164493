import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    TextField,
    MenuItem,
    Button,
} from '@mui/material';

const Reports = () => {
    const [reportData, setReportData] = useState([]);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [month, setMonth] = useState(new Date().getMonth() + 1); // Defaults to current month
    const [year, setYear] = useState(new Date().getFullYear()); // Defaults to current year

    const fetchReportData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/reports/assets-report?month=${month}&year=${year}`
            );
            setReportData(response.data);
            const total = response.data.reduce((sum, item) => sum + item.revenue, 0);
            setTotalRevenue(total);
        } catch (error) {
            console.error('Error fetching report data:', error);
        }
    };

    useEffect(() => {
        fetchReportData();
    }, []);

    const handleGenerateReport = () => {
        fetchReportData();
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-CA');
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Financial Forecast Report
            </Typography>
            <Box display="flex" alignItems="center" mb={3}>
                <TextField
                    select
                    label="Month"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    variant="outlined"
                    size="small"
                    style={{ marginRight: 20 }}
                >
                    {[...Array(12)].map((_, index) => (
                        <MenuItem key={index + 1} value={index + 1}>
                            {new Date(0, index).toLocaleString('default', { month: 'long' })}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    label="Year"
                    type="number"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    variant="outlined"
                    size="small"
                    style={{ marginRight: 20 }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGenerateReport}
                    size="medium"
                    style={{ height: 40 }}
                >
                    Generate Report
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Asset</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Revenue</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Sale Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Revenue Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    {item.accountId ? (
                                        <Link to={`/edit-account/${item.accountId}`}>
                                            {item.accountName} - {item.productName}
                                        </Link>
                                    ) : (
                                        `${item.accountName} - ${item.productName}`
                                    )}
                                </TableCell>
                                <TableCell>{formatCurrency(item.revenue)}</TableCell>
                                <TableCell>{formatDate(item.saleDate)}</TableCell>
                                <TableCell>{formatDate(item.revenueDate)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>TOTAL</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{formatCurrency(totalRevenue)}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default Reports;
