import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, MenuItem } from '@mui/material';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const ProductForm = () => {
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [listPrice, setListPrice] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            const fetchProduct = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products/${id}`);
                    const product = response.data;
                    setName(product.name);
                    setType(product.type);
                    setListPrice(product.listPrice);
                } catch (error) {
                    console.error('Error fetching product:', error);
                }
            };
            fetchProduct();
        }
    }, [id]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const productData = {
            name,
            type,
            listPrice: parseFloat(listPrice),
        };
        try {
            if (id) {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/products/${id}`, productData);
            } else {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products`, productData);
            }
            navigate('/products'); // Navigate back to the Products page
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };

    const handleCancel = () => {
        navigate('/products');
    };

    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" gutterBottom>
                    {id ? 'Edit Product' : 'Create New Product'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Type"
                        select
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    >
                        <MenuItem value="API">API</MenuItem>
                        <MenuItem value="Database">Database</MenuItem>
                        <MenuItem value="Job">Job</MenuItem>
                    </TextField>
                    <TextField
                        label="List Price"
                        type="number"
                        value={listPrice}
                        onChange={(e) => setListPrice(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <Box mt={2}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mr: 2 }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default ProductForm;
