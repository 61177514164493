import React, { useState, useEffect } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Grid,
    MenuItem,
    IconButton,
    Typography,
    Box,
    Menu,
} from '@mui/material';
import { Edit, Delete, Search } from '@mui/icons-material';

const ItemTable = ({ items, onItemsChange, products }) => {
    const [open, setOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [newItem, setNewItem] = useState({ name: '', notes: '', quantity: 1, cost: 0, total: 0 });
    const [productMenuAnchorEl, setProductMenuAnchorEl] = useState(null);

    useEffect(() => {
        if (editIndex >= 0 && products.length > 0) {
            const currentItem = items[editIndex];
            const currentProduct = products.find(product => product.name === currentItem.name);
            setNewItem(currentItem);
            setProductMenuAnchorEl(null);
        }
    }, [editIndex, products, items]);

    const handleAddItem = () => {
        setOpen(true);
        setEditIndex(-1);
        setNewItem({ name: '', notes: '', quantity: 1, cost: 0, total: 0 });
        setProductMenuAnchorEl(null);
    };

    const handleEditItem = (index) => {
        setOpen(true);
        setEditIndex(index);
    };

    const handleSaveItem = () => {
        const updatedItems = [...items];
        if (editIndex >= 0) {
            updatedItems[editIndex] = newItem;
        } else {
            updatedItems.push(newItem);
        }
        onItemsChange(updatedItems);
        setOpen(false);
        setNewItem({ name: '', notes: '', quantity: 1, cost: 0, total: 0 });
    };

    const handleRemoveItem = (index) => {
        const updatedItems = items.filter((item, i) => i !== index);
        onItemsChange(updatedItems);
    };

    const handleNewItemChange = (e, fieldName = null) => {
        const { name, value } = e.target;
        setNewItem((prevNewItem) => {
            const updatedItem = {
                ...prevNewItem,
                [fieldName || name]: value,
            };
            updatedItem.total = updatedItem.quantity * updatedItem.cost;
            return updatedItem;
        });
    };

    const handleProductSelect = (product) => {
        setNewItem({
            ...newItem,
            name: product.name,
            cost: product.listPrice,
            quantity: 1,
            total: 1 * product.listPrice
        });
        setProductMenuAnchorEl(null);
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const calculateInvoiceTotal = () => {
        return items.reduce((sum, item) => sum + item.total, 0);
    };

    return (
        <div>
            <Typography variant="h6">Items</Typography>
            <Button variant="contained" color="primary" onClick={handleAddItem}>
                Add Item
            </Button>
            <TableContainer component={Paper} style={{ marginTop: 16 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Notes</TableCell>
                            <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Quantity</TableCell>
                            <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Cost</TableCell>
                            <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Total</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '150px' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.notes}</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>{item.quantity}</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>{formatCurrency(item.cost)}</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>{formatCurrency(item.total)}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEditItem(index)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => handleRemoveItem(index)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="flex-end" mt={2}>
                <Typography variant="h6">
                    Invoice Total: {formatCurrency(calculateInvoiceTotal())}
                </Typography>
            </Box>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{editIndex >= 0 ? 'Edit Item' : 'Add Item'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <br/>
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <TextField
                                name="name"
                                label="Product"
                                value={newItem.name}
                                onChange={handleNewItemChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                onClick={(event) => setProductMenuAnchorEl(event.currentTarget)}
                            >
                                <Search />
                            </IconButton>
                            <Menu
                                anchorEl={productMenuAnchorEl}
                                keepMounted
                                open={Boolean(productMenuAnchorEl)}
                                onClose={() => setProductMenuAnchorEl(null)}
                            >
                                {products.map((product) => (
                                    <MenuItem key={product._id} onClick={() => handleProductSelect(product)}>
                                        {product.name} - {formatCurrency(product.listPrice)}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="notes"
                                label="Notes"
                                value={newItem.notes}
                                onChange={handleNewItemChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="quantity"
                                label="Quantity"
                                type="number"
                                value={newItem.quantity}
                                onChange={handleNewItemChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="cost"
                                label="Cost"
                                type="number"
                                value={newItem.cost}
                                onChange={handleNewItemChange}
                                fullWidth
                                inputProps={{ step: "0.01" }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveItem} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ItemTable;
