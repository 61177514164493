import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Typography, IconButton, TableSortLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Opportunities = () => {
    const [opportunities, setOpportunities] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOpportunities = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/opportunities?populate=account,product`);
                setOpportunities(response.data);
            } catch (error) {
                console.error('Error fetching opportunities:', error);
            }
        };

        fetchOpportunities();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/opportunities/${id}`);
            setOpportunities(opportunities.filter(opportunity => opportunity._id !== id));
        } catch (error) {
            console.error('Error deleting opportunity:', error);
        }
    };

    const handleEdit = (id) => {
        navigate(`/edit-opportunity/${id}`);
    };

    const handleCreate = () => {
        navigate('/new-opportunity');
    };

    const formatStatus = (status) => {
        return status.charAt(0).toUpperCase() + status.slice(1);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const statusOrder = {
        'inception': 1,
        'price submitted': 2,
        'invoice': 3,
        'closed won': 4,
        'closed lost': 5
    };

    const sortedOpportunities = opportunities.slice().sort((a, b) => {
        if (orderBy === 'name') {
            const nameA = `${a.account.name} - ${a.product.name}`.toUpperCase();
            const nameB = `${b.account.name} - ${b.product.name}`.toUpperCase();
            if (nameA < nameB) return order === 'asc' ? -1 : 1;
            if (nameA > nameB) return order === 'asc' ? 1 : -1;
            return 0;
        }
        if (orderBy === 'price') {
            return order === 'asc' ? a.price - b.price : b.price - a.price;
        }
        if (orderBy === 'created') {
            return order === 'asc' ? new Date(a.created) - new Date(b.created) : new Date(b.created) - new Date(a.created);
        }
        if (orderBy === 'stage') {
            const stageA = statusOrder[a.stage];
            const stageB = statusOrder[b.stage];
            if (stageA < stageB) return order === 'asc' ? -1 : 1;
            if (stageA > stageB) return order === 'asc' ? 1 : -1;
            return 0;
        }
        return 0;
    });

    const getRowStyle = (stage) => {
        switch (stage) {
            case 'closed lost':
                return { backgroundColor: 'lightgrey', color: 'white' };
            case 'invoice':
                return { backgroundColor: '#DAF7A6', color: 'black' };
            case 'closed won':
                return { backgroundColor: '#76ca2b', color: 'white' };
            default:
                return {};
        }
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Opportunities
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleCreate}
                sx={{ mb: 2 }}
            >
                New Opportunity
            </Button>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'name'}
                                    direction={orderBy === 'name' ? order : 'asc'}
                                    onClick={() => handleRequestSort('name')}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'price'}
                                    direction={orderBy === 'price' ? order : 'asc'}
                                    onClick={() => handleRequestSort('price')}
                                >
                                    Price
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'created'}
                                    direction={orderBy === 'created' ? order : 'asc'}
                                    onClick={() => handleRequestSort('created')}
                                >
                                    Created Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={orderBy === 'stage'}
                                    direction={orderBy === 'stage' ? order : 'asc'}
                                    onClick={() => handleRequestSort('stage')}
                                >
                                    Status
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedOpportunities.map((opportunity) => (
                            <TableRow key={opportunity._id} sx={getRowStyle(opportunity.stage)}>
                                <TableCell>{`${opportunity.account.name} - ${opportunity.product.name}`}</TableCell>
                                <TableCell>${opportunity.price.toFixed(2)}</TableCell>
                                <TableCell>{new Date(opportunity.created).toLocaleDateString()}</TableCell>
                                <TableCell>{formatStatus(opportunity.stage)}</TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() => handleEdit(opportunity._id)}
                                        sx={{ mr: 1 }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => handleDelete(opportunity._id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default Opportunities;
